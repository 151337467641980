<template>
    <div class="modal fade" tabindex="-1" id="AdminAddGroupModal" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Ajouer un nouveau Groupe</h5>
                <button type="button" class="btn-close" @click.prevent="close()" aria-label="Close"></button>
            </div>
            <div class="modal-body" v-if="loaded">
                <div class="form">
                    <div class="mb-3">
                        <label for="" class="form-label">Nom</label>
                        <input type="text" name="" id="" class="form-control" v-model="newName">
                    </div>
                    <div class="mb-3" v-if="userInfo.authLevel === 0">
                        <label for="" class="form-label">Propriétaire</label>
                        <select name="" id="" class="form-select" v-model="owner_id">
                            <option :value="user._id" v-for="user in users" :key="user._id">{{user.lastName}} {{user.firstName}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="modal-body" v-else>
                <p>Loading...</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click.prevent="close()">Annuler</button>
                <button type="button" class="btn btn-primary" @click.prevent="add">Créer</button>
            </div>
            </div>
        </div>
    </div>
</template>

<script setup>

    import axios from "axios";
    import {ref, defineProps, onMounted, defineEmits} from "vue";
    import {Modal} from "bootstrap";

    let emit = defineEmits([
        "close",
        "added"
    ])

    defineProps([
        "show",
        "userInfo"
    ])

    let loaded = ref(false);
    let users = ref([]);
    let owner_id = ref("");

    let newName = ref("");


    onMounted( () => {
        var myModal = Modal.getOrCreateInstance(document.querySelector('#AdminAddGroupModal'));
        myModal.show();
        
        axios.get("/api/users")
            .then( answer => {
                users.value = answer.data.filter( u => u.authLevel <= 1 );
                loaded.value = true;
            })
            .catch( e => {
                alert(e)
            })
    })

    function close(){
        var myModal = Modal.getOrCreateInstance(document.querySelector('#AdminAddGroupModal'));
        myModal.hide();
        emit("close");
    }

    function add(e){
        axios.post("/api/groups", {
            name: newName.value
        })
        .then( answer => {
            emit("added", answer);
            close();
        })
        .catch( e => {
            alert(e.message);
            emit("error",e);
        })
    }

</script>