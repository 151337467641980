<template>

    <div class="alert alert-warning" v-if="userInfo.authLevel > 0" role="alert">
        <h6 class="alert-heading"><i class="bi bi-exclamation-triangle-fill me-2"></i>Attention</h6>
        <p>Votre niveau d'abilitation ne vous permet pas de voir tout les groupes. Vous ne voyez donc que les groupes que vous avez créés.</p>
    </div>

    <div class="container-fluid" v-if="loaded">
        <p class="text-end">
            <button class="btn btn-sm btn-primary me-2" @click.prevent="showImport = true">
                <i class="bi bi-files-alt"></i>
                Importer
            </button>
            
            <button class="btn btn-sm btn-primary" @click.prevent="showModal">
                <i class="bi bi-plus"></i>
                Ajouter
            </button>
        </p>

        <table class="table">
            <thead>
                <tr>
                    <th>Nom</th>
                    <th>Propriétaire</th>
                    <th>Total membre(s)</th>
                    <th class="text-end">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="groups.length === 0">
                    <td colspan="4" class="text-center"><i class="bi bi-info-circle-fill me-2"></i>Aucun groupe</td>
                </tr>
                <tr v-else v-for="group in groups" :key="group._id">
                    <td>{{ group.name }}</td>
                    <td>{{ (group.owner_id) ? group.owner_id.firstName+" "+group.owner_id.lastName : "?"  }}</td>
                    <td>{{ group.members.length }}</td>
                    <td class="text-end">
                        <div class="btn-group" role="group" aria-label="Basic example">
                            <button type="button" class="btn btn-sm btn-danger" @click="deleteGroup(group)" v-if="userInfo.authLevel === 0"><i class="bi bi-trash"></i></button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="container-fluid" v-else>
        <p>Loading...</p>
    </div>

    <admin-add-group-vue v-if="show" :user-info="userInfo" @close="closeModal" @added="load"/>
    <admin-import-to-group-vue v-if="showImport" :user-info="userInfo" @close="showImport = false" @added="load" />

</template>

<script setup>

    import AdminAddGroupVue from './modals/AdminAddGroup.vue';
    import AdminImportToGroupVue from './modals/AdminImportToGroup.vue';

    import { defineProps, onMounted, ref } from '@vue/runtime-core';
    import axios from 'axios';

    let groups = ref([]);
    let loaded = ref(false);
    let error = ref(null);
    let show = ref(false);
    let showImport = ref(false);

    defineProps([
        "userInfo"
    ]);

    /**
     * Load all groups
     */
    onMounted( () => {
        load()
    })

    function showModal(){
        show.value = true;
    }

    function closeModal(){
        show.value = false;
    }

    function load(){
        axios.get("/api/groups")
            .then( (response) => {
                groups.value = response.data;
                loaded.value = true;
            })
            .catch( (e) => {
                error.value = (e.response) ? e.response.data.message : e ;
            })
    }

    function deleteGroup(group){
        axios.delete("/api/groups/"+group._id)
        .then( answer => {
            load();
        })
        .catch( e => {
            alert(e);
        })
    }

</script>