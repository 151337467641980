<template>
    <div class="modal fade" tabindex="-1" id="AdminImportToGroupModal" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Importer un groupe</h5>
                <button type="button" class="btn-close" @click.prevent="close()" aria-label="Close"></button>
            </div>
            <div class="modal-body" v-if="loaded">
                <div class="mb-3">
                    <label for="" class="form-label">Nom du groupe</label>
                    <input type="text" class="form-control" v-model="name">
                </div>
                <div class="mb-3">
                    <label for="" class="form-label">Liste d'email à importer <small><i>séparées par un saut de ligne</i></small></label>
                    <textarea cols="30" rows="10" class="form-control" v-model="emails"></textarea>
                </div>
                <div class="mb-3" v-if="userInfo.authLevel === 0">
                    <label for="" class="form-label">Propriétaire</label>
                    <select name="" id="" class="form-select" v-model="owner_id">
                        <option :value="user._id" v-for="user in users" :key="user._id">{{user.lastName}} {{user.firstName}}</option>
                    </select>
                </div>
                <small class="text-justify"><i>Les utilisateurs n'existants pas seront créés et ajoutés au groupe, dans le cas contraire, ils seront simplement ajoutés.</i></small>
            </div>
            <div class="modal-body" v-else>
                <p>Loading...</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click.prevent="close()">Annuler</button>
                <button type="button" class="btn btn-primary" @click.prevent="add">Importer</button>
            </div>
            </div>
        </div>
    </div>
</template>

<script setup>

    import axios from "axios";
    import {ref, defineProps, onMounted, defineEmits} from "vue";
    import Modal from "bootstrap/js/dist/modal";

    let emit = defineEmits([
        "close",
        "added"
    ])

    defineProps([
        "show",
        "userInfo"
    ])

    let loaded = ref(false);
    let name = ref("");
    let emails = ref("");
    let users = ref([]);

    onMounted( () => {
        var myModal = Modal.getOrCreateInstance(document.querySelector('#AdminImportToGroupModal'));
        myModal.show();
        load();
    })

    function load(){
        axios.get("/api/users")
            .then( answer => {
                users.value = answer.data.filter( u => u.authLevel <= 1 );
                loaded.value = true;
            })
            .catch(e => {
                alert(e);
            })
    }

    function close(){
        var myModal = Modal.getOrCreateInstance(document.querySelector('#AdminImportToGroupModal'));
        myModal.hide();
        emit("close");
    }

    function add(){
        axios.post("/api/groups/import", {
            name: name.value,
            emails: emails.value.split("\n")
        })
        .then( answer => {
            emit("added", answer.data);
            close();
        })
        .catch( e => {
            alert(e.message);
        })
    }

</script>